const mcNav = [
    {
      label: 'Portfolios', 
      path: '/model/portfolios',
      componentName: 'Portfolios',
      hiddenFromNav: true,
    },
   {
      label: 'Calling Intensities', 
      path: '/model/intensities',
      componentName: 'CallingIntensitiesTab',
      hiddenFromNav: true,
    },
    {
      label: 'Calls Per Associate Hour', 
      path: '/model/callrate',
      componentName: 'CallsPerAssociateHourTab',
      hiddenFromNav: true,
    },
    {
      label: 'Minimum Callable Balance', 
      path: '/model/callminimum',
      componentName: 'MinimumCallableBalanceTab',
      hiddenFromNav: true,
    },
    {
      label: 'Collection Targets', 
      path: '/model/targets',
      componentName: 'CollectionTargetsTab',
      hiddenFromNav: true,
    },
    {
      label: 'Staffing Schedules', 
      path: '/model/schedules',
      componentName: 'StaffingSchedulesTab',
      hiddenFromNav: true,
    },
    {
      label: 'Backup Campaigns', 
      path: '/model/backups',
      componentName: 'BackupCampaignsTab',
      hiddenFromNav: true,
    },
  ]

export default mcNav;