import React, { useContext } from "react";
import { Link } from "gatsby";
import { AiOutlineMenu } from "react-icons/ai";
import * as styles from "../styles/navbar.module.css";
import ReactTooltip from "react-tooltip";
import { userCanAccessPage } from "../config/navData";
import { navData } from "../config/navData";
import UserContext from "./UserContext";
import Button from "@mui/material/Button";
import { Avatar, Popover, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { logout } from "../utils/Auth";

function NavBar() {
  const { userGroups } = useContext(UserContext);
  const { isOpen, clickHandler } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { initials, user } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className={
        isOpen ? styles.NavBar : [styles.NavBar, styles.IsClosed].join(" ")
      }
    >
      <AiOutlineMenu
        onClick={clickHandler}
        style={{
          fontSize: "30px",
          paddingTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginBottom: "10px",
          color: "white",
        }}
      />
      <ul className={styles.NavBarList}>
        {navData
          .filter(
            (page) => !page.hiddenFromNav && userCanAccessPage(page, userGroups)
          )
          .map((val, key) => {
            return (
              <Link
                data-tip={val.title}
                to={val.path}
                style={{
                  textDecoration: "none",
                  fontFamily: "Montserrat, sans-serif",
                  color: "white",
                }}
                key={`Link: ${key}`}
              >
                <li key={key} className="row">
                  {val.icon} <span>{val.title}</span>
                </li>
              </Link>
            );
          })}
      </ul>
      <Box
        className={isOpen ? styles.theBox : styles.theBoxClosed}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          variant="outlined"
          sx={{ bgcolor: "whiteSmoke" }}
          onClick={() => logout()}
          className={styles.muiButton}
        >
          Log{!isOpen && <br></br>}out
        </Button>
        <Box mx={1}></Box>
        <Avatar
          className={styles.theAvatar}
          sx={{ bgcolor: "#a08df3" }}
          onClick={handleClick}
        >
          {initials}
        </Avatar>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>User: {user}</Typography>
        </Popover>
      </Box>

      {isOpen ? null : <ReactTooltip />}
    </div>
  );
}

export default NavBar;
