/** @format */

import { get, put, post } from "../httpClient";
// Dummy Endpoint

async function getDummyCall() {
  const url = "/experiments";

  let response = await get(url);

  return response;
}

{
  /*Campaigns*/
}
async function getCampaigns(rowsPerPage, searchParams) {
  const url = "juniper/campaigns";
  const params = {
    offset: 0,
    limit: rowsPerPage,
    ...searchParams,
  };

  let response = await get(url, params);
  return response;
}

async function getPage(params) {
  const url = "juniper/campaigns";

  let response = await get(url, params);

  return response;
}

async function getCampaignEvents(campaignID) {
  const url = `juniper/campaign/${campaignID}`;
  let response = await get(url);
  return response;
}

//Trigger Campaign
async function postCampaignID(campaign_id) {
  const url = `juniper/campaign/${campaign_id}/trigger`;
  let response = await post(url);
  return response;
}

async function putCancelCampaign(campaign_id) {
  const url = `juniper/campaign/${campaign_id}`;

  const formData = new FormData();
  formData.append("campaign_status", "cancel");

  let response = await put(url, formData);
  return response;
}

async function postCampaign(matchingTemplate, subjectValue, file, formattedValue) {
  const url = "juniper/campaign";
  const formData = new FormData();
  formData.append("template", matchingTemplate);
  formData.append("subject_id", subjectValue);

  formData.append("file", file);
  formData.append("scheduled_time", formattedValue);

  let response = await post(url, formData);
  return response;
}

async function getSubjects() {
  const url = "juniper/subjects";
  const params = {
    limit: 100,
  };
  let response = await get(url, params);
  return response;
}

/*Subjects*/

async function postSubject(subject, language, parentSubj) {
  const url = "juniper/subject";

  const formData = new FormData();
  formData.append("subject", subject);

  // fix this accordingly
  formData.append("language", language);
  parentSubj !== null && formData.append("english_parent_id", parentSubj);

  let response = await post(url, formData);
  return response;
}

/*Templates*/

async function getTemplates(isChecked, isEngChecked, searchValue) {
  const url = "juniper/templates";
  const params = { include_inactive: isChecked, english_only: isEngChecked, template_search: searchValue };
  let response = await get(url, params);
  return response;
}

async function getTemplateID(templateID) {
  const url = `juniper/template/${templateID}`;

  let response = await get(url);

  return response;
}

async function putTemplateID(templateID, rawHTML, subjectValue, language) {
  const url = `juniper/template/${templateID}`;
  const formData = new FormData();

  formData.append("active", true);
  formData.append("subject_id", subjectValue);
  formData.append("raw_html", rawHTML);
  formData.append("language", language);

  let response = await put(url, formData);

  return response;
}

async function postTemplate(inputName, subject, language, parentTemp, rawHTML) {
  const url = "juniper/template";
  const formData = new FormData();
  formData.append("name", inputName);
  formData.append("subject_id", subject);
  formData.append("raw_html", rawHTML);

  formData.append("language", language);
  parentTemp !== null &&  formData.append("english_parent_id", parentTemp);

  let response = await post(url, formData);
  return response;
}

/*Images*/

async function getImages() {
  const url = "juniper/images";
  let response = await get(url);
  return response;
}

async function postImages(inputName, file) {
  const url = "juniper/images";

  const formData = new FormData();
  formData.append("name", inputName);
  formData.append("file", file);

  let response = await post(url, formData);
  return response;
}

/*Audit*/

async function getEmails(rowsPerPage, searchParams) {
  const url = "juniper/emails";
  const params = {
    offset: 0,
    limit: rowsPerPage,
    ...searchParams,
  };
  let response = await get(url, params);
  console.log(params)
  return response;
}

async function getPageAudit(params) {
  const url = "juniper/emails";

  let response = await get(url, params);
  return response;
}

async function getEmailEvents(soc_message_id) {
  const url = `juniper/email/${soc_message_id}/events`;
  let response = await get(url);
  return response;
}

export {
  getDummyCall,
  getCampaigns,
  getPage,
  getCampaignEvents,
  postCampaignID,
  postCampaign,
  putCancelCampaign,
  getSubjects,
  postSubject,
  getTemplates,
  getTemplateID,
  putTemplateID,
  postTemplate,
  getImages,
  postImages,
  getEmails,
  getEmailEvents,
  getPageAudit,
};
